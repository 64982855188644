import React from 'react';
import styled from '@emotion/styled';
import {ReactComponent as DocsIcon} from './logo/podkite-logo.svg';

const Wrapper = styled.div({
  display: 'flex',
  fontSize: 24
});

const StyledDocsIcon = styled(DocsIcon)({
  height: '1.3em',
  marginTop: '0.07142857143em'
});

export default function Logo() {
  return (
    <Wrapper>
      <StyledDocsIcon />
    </Wrapper>
  );
}
