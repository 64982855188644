import React from 'react';
import {Helmet} from 'react-helmet';

export default function SEO({title, description, siteName}) {
  return (
    <Helmet>
      <link rel="icon" href="/images/favicon.ico" />
      <script type="text/javascript" src="https://embed.podkite.com/v1/loader.js"></script>
    </Helmet>
  );
}